<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-historial-estado">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">
              Historial de cambio {{ form_historial.titulo }}
            </h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div
            class="modal-body"
            v-if="entidades !== null && entidades != undefined"
          >
            <div>
              <table
                class="
            table table-bordered table-striped table-hover table-sm
          "
              >
                <thead>
                  <tr>
                    <th align="center">Valor anterior</th>
                    <th align="center">Valor nuevo</th>
                    <th>Justificación</th>
                    <th>Usuario</th>
                    <th>Fecha</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="entidad in entidades.data" :key="entidad.id">
                    <td align="center">
                      <span
                        class="badge"
                        v-bind:class="[
                          entidad.valor_ant == 1
                            ? 'badge-success'
                            : 'badge-danger',
                        ]"
                      >
                        {{ entidad.nEstadoAnterior }}
                      </span>
                    </td>
                    <td align="center">
                      <span
                        class="badge"
                        v-bind:class="[
                          entidad.valor_nuevo == 1
                            ? 'badge-success'
                            : 'badge-danger',
                        ]"
                      >
                        {{ entidad.nEstadoNuevo }}
                      </span>
                    </td>
                    <td>{{ entidad.justificacion }}</td>
                    <td>{{ entidad.user.name }}</td>
                    <td>
                      {{ moment(entidad.created_at).format("YYYY-MM-DD HH:mm:ss") }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="card-footer">
              <div class="float-left" v-if="entidades.total">
                <p>
                  Mostrando del <b>{{ entidades.from }}</b> al
                  <b>{{ entidades.to }}</b> de un total:
                  <b>{{ entidades.total }}</b> Registros
                </p>
              </div>
              <div class="float-left" v-else>
                <p>
                  <span class="badge badge-danger">
                    No hay registros para mostrar
                  </span>
                </p>
              </div>
              <pagination
                :data="entidades"
                @pagination-change-page="buscarEntidad"
                :limit="5"
                align="right"
              ></pagination>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import pagination from "laravel-vue-pagination";
import moment from "moment";

export default {
  name: "HistorialEstado",
  components: {
    pagination,
  },
  data() {
    return {
      form_historial: { entidad: null, entidad_id: null, titulo: null },
      entidades: null,
      moment: moment,
    };
  },

  methods: {
    async llenar_modal(entidad, entidad_id) {
      this.form_historial.entidad = entidad;
      this.form_historial.entidad_id = entidad_id;
      if (entidad == "App\\Conductor") {
        this.form_historial.titulo = "Conductor";
      }

      if (entidad == "App\\Remolque") {
        this.form_historial.titulo = "Remolque";
      }

      if (entidad == "App\\Vehiculo") {
        this.form_historial.titulo = "Vehiculo";
      }
      if (entidad == "App\\Remolcador") {
        this.form_historial.titulo = "Remolcador";
      }
      if (entidad == "App\\User") {
        this.form_historial.titulo = "Usuario";
      }
      await this.buscarEntidad();
    },

    async buscarEntidad(page = 1) {
      await axios
        .get("/api/admin/historicoEstado?page=" + page, {
          params: this.form_historial,
        })
        .then((response) => {
          this.entidades = response.data;
        });
    },
  },
};
</script>
